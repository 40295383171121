export const selectVersion = () => {
  const random = Math.random()
  return random < 0.5
    ? 'A'
    : 'B'
}

export const selectVersionByConfig = (versionProbabilities: Record<string, number>) => {
  const probabilitiesSum = Object.values(versionProbabilities).reduce((acc, probability) => acc + probability, 0)
  if (probabilitiesSum !== 1) throw new Error('All probabilities sum should be equal to 1')

  const random = Math.random()

  let currentProbability = 0
  for (const [version, probability] of Object.entries(versionProbabilities)) {
    currentProbability += probability
    if (random < currentProbability) {
      return version
    }
  }
}
