import { SCREEN } from '@/utils/segment'
import { selectVersion } from '@/utils/abTesting'
export const getTriggerDate = (environment: string) => {
  return environment === 'buyer-web-front-production' ? getTriggerDateProduction() : getTriggerDateForTests()
}

const getTriggerDateForTests = () => {
  const triggerDate = new Date()
  triggerDate.setMinutes(triggerDate.getMinutes() + 5)
  triggerDate.setSeconds(0)
  return triggerDate.toISOString().split('.')[0] + 'Z'
}

const getTriggerDateProduction = () => {
  const numDaysAhead = 1
  const currentDateColombia = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Bogota' }))
  currentDateColombia.setDate(currentDateColombia.getDate() + numDaysAhead)
  const utcDate = new Date(Date.UTC(currentDateColombia.getFullYear(), currentDateColombia.getMonth(), currentDateColombia.getDate(), 14, currentDateColombia.getMinutes(), currentDateColombia.getSeconds()))
  return utcDate.toISOString().split('.')[0] + 'Z'
}

export const getEntryNameAndEntryNameAlias = (screen: string) => {
  const version = selectVersion()
  if (screen === SCREEN.INVESTMENT_QUIZ) screen = SCREEN.SEARCH_RESULTS
  return entryNamesAndEntryNameAliases[screen][version]
}

const entryNamesAndEntryNameAliases = {
  [SCREEN.SEARCH_RESULTS]: {
    A: {
      entryName: 'CLUSTER',
      entryNameAlias: 'CLUSTER_REGISTERED_A'
    },
    B: {
      entryName: 'CLUSTER',
      entryNameAlias: 'CLUSTER_REGISTERED_B'
    }
  },
  [SCREEN.PDP_INVESTORS]: {
    A: {
      entryName: 'PROJECT',
      entryNameAlias: 'PROJECT_REGISTERED_A'
    },
    B: {
      entryName: 'QUOTATION',
      entryNameAlias: 'QUOTATION_REGISTERED_A'
    }
  }
}
