<script lang="ts" setup>
import { ref, computed } from 'vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import LockIcon from '@lahaus-roomie/roomie/src/assets/icons/lock.svg'
import UnlockIcon from '@lahaus-roomie/roomie/src/assets/icons/unlock.svg'
import UrbanIcon from '@lahaus-roomie/roomie/src/assets/icons/urban.svg'
import EarningsIcon from '@lahaus-roomie/roomie/src/assets/icons/earnings.svg'
import type { Props } from './types'
import AppRegisterForm from '@/components/App/RegisterForm/index.vue'
import { LazyAppModal } from '#components'

const props = withDefaults(defineProps<Props>(), {
  showGeneralTitle: false
})
const emit = defineEmits(['registration-submit'])
const { t } = useI18n()

const isRegistrationModalOpen = ref(false)

const title = computed(() => props.showGeneralTitle ? t('title.general') : t('title.singular'))
</script>

<script lang="ts">
export default {
  name: 'LockOverlay'
}
</script>

<template>
  <div class="lock-overlay flex flex-col justify-end md:justify-center items-center w-full p-20">
    <Teleport to="body">
      <LazyAppModal
        v-if="isRegistrationModalOpen"
        class="lock-overlay__registration-modal !fixed top-0 z-60"
        @close="isRegistrationModalOpen = false">
        <AppRegisterForm
          :id="`${screen}-registration-modal-form`"
          :listing-id
          :listing-ids
          :hub-code
          :screen
          :show-general-title
          @submit="(form) => emit('registration-submit', form)" />
      </LazyAppModal>
    </Teleport>

    <div class="lock-overlay__content mb-50 lg:mb-0">
      <div class="lock-overlay__icon-divider flex items-center w-full gap-6 mb-10 md:mb-24">
        <div class="border border-carbon-light-300 rounded-circle w-48 h-48 flex justify-center items-center shrink-0">
          <LockIcon class="fill-carbon-800" />
        </div>
      </div>

      <div class="text-center">
        <h1
          v-sanitize.basic="title"
          class="text-24 md:text-40 font-bold mb-32" />

        <div class="px-12">
          <ul class="text-14 md:text-16 text-carbon-600 mb-24 flex flex-col lg:flex-row lg:justify-center gap-8 md:gap-24">
            <li class="inline-flex gap-10 text-left">
              <UrbanIcon class="w-20 h-20 fill-carbon-800" />

              <p>{{ t('features.projects') }}</p>
            </li>

            <li class="inline-flex gap-10 text-left">
              <EarningsIcon class="w-20 h-20 fill-carbon-800" />

              <p>{{ t('features.paymentPlans') }}</p>
            </li>
          </ul>
        </div>

        <RoomieButton
          :id="`${screen}-lock-overlay-cta`"
          :data-lh-id="`${screen}-lock-overlay-cta`"
          type="button"
          icon-placement="left"
          class="text-16 w-full lg:w-fit"
          @click="isRegistrationModalOpen = true">
          {{ t('cta.label') }}

          <template #icon>
            <UnlockIcon class="fill-white pointer-events-none" />
          </template>
        </RoomieButton>
      </div>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json" />

<style lang="scss" src="./_index.scss" />
